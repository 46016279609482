import { createContext, useState, useEffect, useContext } from 'react';
import { saveToLocalPoll } from '@/utils/builder';

const INITIAL_VALUES = {};

const BuilderContext = createContext(INITIAL_VALUES);

export const BuilderProvider = props => {
  const { children, initialModel } = props;
  const [state, setState] = useState(initialModel || INITIAL_VALUES);

  useEffect(() => {
    console.log('updating initial model');
    setState(initialModel || INITIAL_VALUES);
  }, [initialModel]);

  const saveLocalPoolModel = async newState => {
    const stateToSave = newState || state; // check if passing new state, otherwise, save current context state
    await saveToLocalPoll(stateToSave);
    return Promise.resolve();
  };

  const setModel = async newContext => {
    const newState = {
      ...state,
      ...newContext
    };
    await setState(newState);
    await saveLocalPoolModel(newState);
  };

  return (
    <BuilderContext.Provider
      value={{
        model: state,
        setModel,
        saveLocalPoolModel
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

export const BuilderConsumer = BuilderContext.Consumer;
export const useBuilderContext = () => useContext(BuilderContext);
