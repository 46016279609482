import localForage from '@/lib/localForage';

export const getLocalPoll = async customKey => {
  if (typeof window === 'undefined') {
    return;
  }
  const keyName = customKey || 'currentPoolModel';
  const localModel = await localForage.getItem(keyName);
  if (!localModel) {
    return null;
  }
  if (localModel === 'undefined') {
    return null;
  }
  return localModel;
};

export const initNewLocalPoll = async (customKey, customListKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const keyName = customKey || 'currentPoolModel';
  const listName = customListKey || 'localPollList';
  // first, check if previous currentPoll on storage
  const localModel = await localForage.getItem(keyName);
  if (!localModel) {
    // if not previous poll, init new poll (return null)
    return null;
  }
  // if previous localPoll, save it to localPollList
  if (localModel) {
    // const parsedModel = JSON.parse(localModel);
    const parsedModel = localModel;
    // if localModel, but not valid
    if (!parsedModel.title) {
      return null;
    }
    const localList = await localForage.getItem(listName);
    // if no localList, create new List and store
    if (!localList) {
      const newLocalList = { [parsedModel.id]: parsedModel };
      await localForage.setItem(listName, newLocalList);
    }
    if (localList) {
      // if local list, add currentPoll to list
      const updatedList = { ...localList, [parsedModel.id]: parsedModel };
      // set updated list
      await localForage.setItem(listName, updatedList);
    }
    // finally, remove localModel to start fresh
    await localForage.removeItem(keyName);
  }
  return Promise.resolve();
};

export const getLocalPollList = async customListKey => {
  if (typeof window === 'undefined') {
    return;
  }
  const listName = customListKey || 'localPollList';
  const localList = await localForage.getItem(listName);
  if (!localList) {
    return null;
  }
  if (localList === 'undefined') {
    return null;
  }
  return Promise.resolve(localList);
};

export const getPreviousFomLocalPollList = async (id, customListKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const listName = customListKey || 'localPollList';
  const localList = await localForage.getItem(listName);
  if (!localList) {
    return null;
  }
  if (localList === 'undefined') {
    return null;
  }
  const prevModel = { ...localList[id] };
  return Promise.resolve(prevModel);
};

export const deleteFomLocalPollList = async (id, customListKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const listName = customListKey || 'localPollList';
  const localList = await localForage.getItem(listName);
  if (!localList) {
    return null;
  }
  if (localList === 'undefined') {
    return null;
  }

  const updatedList = { ...localList };
  delete updatedList[id];
  // set updated list
  await localForage.setItem(listName, updatedList);
  return Promise.resolve(updatedList);
};

export const saveToLocalPoll = async (pollData, customKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const keyName = customKey || 'currentPoolModel';
  await localForage.setItem(keyName, pollData);
  return Promise.resolve(pollData);
};

// if return promise, set as async?
export const clearLocalPollData = async customKey => {
  if (typeof window === 'undefined') {
    return;
  }
  const keyName = customKey || 'currentPoolModel';
  // get current local model
  const localModel = await getLocalPoll();
  // remove local model if on local list
  await deleteFomLocalPollList(localModel.id);
  // remove local poll model
  await localForage.removeItem(keyName);
  return Promise.resolve();
};

export const saveLocalAccessKey = async (pollKeys, customKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const keyName = customKey || 'pollModelKeys';

  const localKeys = await localForage.getItem(keyName);
  if (localKeys) {
    const newKeys = { ...localKeys, ...pollKeys }; // object of { [slug]: accessKey }
    await localForage.setItem(keyName, newKeys);
    return Promise.resolve(newKeys);
  }
  // if not, create new record on local
  await localForage.setItem(keyName, pollKeys);
  return Promise.resolve(pollKeys);
};

export const getLocalAccessKey = async (slug, customKey) => {
  if (typeof window === 'undefined') {
    return;
  }
  const keyName = customKey || 'pollModelKeys';
  let localKeys = null;
  try {
    localKeys = await localForage.getItem(keyName);
  } catch (err) {
    console.log(err);
    localKeys = null;
  }
  if (!localKeys) {
    return null;
  }
  return localKeys[slug];
};
