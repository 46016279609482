import localForage from 'localforage';

// https://localforage.github.io/localForage/#settings-api-config

localForage.config({
  name: 'Pollme App',
  storeName: 'pollme_local'
});

export default localForage;
